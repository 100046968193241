.footer  {
    display: flex;
    justify-content: space-between;
    padding: 5vh 4vw;
    color: #ffffff;
    font-size: 1.6vh;
    .quickLinks {
        display: flex;
        div {
            margin: 0 1vw;
        }
    }
    .left {
        font-size: 1.2vh;
        span {
            img {
                width: 14px;
                height: 14px;
                margin-right: 0.2vw;
            }
        }
    }
    .newsLetter {
        .mailInput {
            display: flex;
            align-items: center;
            input {
                height: 2vh;
                background: #071214;
                padding: 1.1vh 1vh;
                margin: 1vh 0;
                border: none;
                font-size: 1.6vh;
            }
            span {
                background: #A5FDFD;
                padding: inherit;
                padding: 1vh 1vh;
                margin: 0 0 0 0.2vw;
                height: 2vh;
                display: flex;
                align-items: center;

            }
        }

    }
}

@media screen and (min-width: 100px) and (max-width: 768px) {
   .footer {
        width: 100% !important;
        // height: 55vh;
        display: block;
        .left {
            width: 50%;
            float: left;
            margin: 0;
            padding: 0;
            .mediaLink {
                display: flex;
                flex-direction: column;
                span {
                    margin: 1vh 0;
                }
            }
        }
        .right {
            width: 50%;
            float: left;
            margin: 0;
            padding: 0;
        }
        .quickLinks{
            flex-direction: column;
            div {
                margin: 1.5vh 0;
            }
        }
        .newsLetter {
            float: left;
            clear: both;
            margin: 2vh 0;
            width: 90%;
            input {
                width: 88%;
            }
        }
   }
  }
.contactUsWrapper {
    padding: 15vh 15vw;
    .formSection{
        // margin-top: 15vh;
        // height: 40vh;
        display: flex;
        align-items: flex-start;
        box-sizing: border-box;
        box-shadow: 1px 1px 3px 4px rgba(0,0,0,0.62);
        // margin: 15vh 10vw;
        padding: 2vh 3vw;
        border-radius: 8px;
        border: 1px solid #54C9F8;
        position: relative;
        .leftSection {
            width: 55%;
            // border: 1px solid red;
        }
        .rightSection {
            width: 45%;
            // border: 1px solid red;
            display: flex;
            justify-content: center;
            .imgContainer {
                // border: 1px solid red;
                padding-bottom: 5vh;
                margin: 0;
                padding: 0;
                img {
                    width: 35vh;
                    height: 35vh;
                    // position: absolute;
                    // top: 0;
                }
            }
        }
        .MuiInput-input {
            color: #999999;
        }
        .MuiFormGroup-root {
            margin-right: 0;
            padding-right: 0;
            .MuiInput-root {
                width: 47.5%;
                &:last-child {
                    margin: 1vh 0 1vh 1vh;
                }
            }
        }
        .MuiInput-root {
            padding: 0.5vh 0.5vw;
            margin: 1vh 1vw 1vh 0;
            background: #09171A;
            font-size: 1.6vh;
            border-radius: 5px;
            &.selectInput {
                margin-right: 0;
            }
            &::after {
                display: none;
            }
            &::before {
                display: none;
            }
        }
        .MuiInputBase-root {
            border: none;
        }
        // .MuiFormControl-root {
        //     background: #09171A;  
        // }
        .MuiSelect-select {
            border: none;
            // background: #09171A;
            margin: 0.5vh 0 0.5vh 0.5vh;
            // padding: 0;
            // padding: 0.8vh;
            // border-radius: 5px;
            em {
                font-style: normal;
                color: #999999;
            }
        }
        .MuiSvgIcon-root {
            color: #ffffff;
        }
        .selectInput {
            .MuiInput-root {
                padding: 0;
            }
        }
        button {
            background: #A5FDFD;
            color: #000000;
            font-weight: 600;
            text-transform: none;
            width: fit-content;
            font-size: 1.6vh;
            padding: 0.5vh 3vw;
            margin: 2vh 0;
            border-radius: 7px;
        }
    }
}

.MuiPaper-root {
    .MuiMenuItem-root {
        font-size: 1.6vh;
    }
}

@media screen and (min-width: 100px) and (max-width: 768px) {
    .contactUsWrapper {
        padding-top: 5vh;
        .formSection{
            .rightSection {
                display: none;
            }
            .leftSection {
                width: 100%;
            }
            .MuiFormGroup-root {
                .MuiInput-root {
                    width: 100%;
                    padding-left: 1vw;
                    &:last-child {
                        margin: 0;
                        margin: 1vh 0;
                    }
                }
            }
            .MuiInput-input {
                padding-left: 10px;
                font-size: 1.4vh;
            }
            button {
                font-size: 1.4vh;
                padding: 0.5vh 3vw;
                margin: 2vh 0;
            }
        }
    }
  }
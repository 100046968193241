* {
  body {
    background: #141C23;
    margin: 0;
    padding: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'O Sans', 'Helvetica Neue', sans-serif;
  }
 #root {
  margin: 0 !important;
  padding: 0 !important;
  overflow-x: clip;
 }
}
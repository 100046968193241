.nav {
    margin: 0;
    padding: 1vh 0;
    background: rgba(10, 10, 10, 0.4);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    .clearfix {
        width: 100%;
        list-style: none;
        display: inline-flex;
        margin: 0 3vw;
        padding: 0;
        line-height: 5vh;
        font-size: 1.8vh;

        .company {
            width: 10%;
            img {
                height: 4vh;
            }
        }
        .buttonItem {
            width: 8%;
            margin: 1vh 0;
            .button {
                border: 1px solid #54C9F8;
                border-radius: 10px;
                text-align: center; 
                height: 3vh; 
                line-height: 3vh;
                color: #ffffff;
                padding: 0.5vh 1vw;
                cursor: pointer;
            }
        }
        .dropDown {
            display: none;
        }
        li {
            color: #007CAE;
            padding: 0 1vw;
            cursor: pointer;
            &.active {
                color: #ffffff;
                &::after {
                    content: '';
                    background: #ffffff;
                    display: block;
                    height: 2px;
                    width: 20px;
                    padding-left: 1vw;
                }
            }
        }
        .navLinks {
            display: inline-flex;
            width: 72%;
            justify-content: center;
        }
    }
}


//mediaquery

@media screen and (min-width: 100px) and (max-width: 768px) {
    .nav {
        .clearfix {
            .buttonItem {
                display: none;
            }
            .dropDown {
                display: block;
                padding: 1% 7%;
                position: absolute;
                right: 0;
                z-index: 3;
            }
            .navLinks {
                display: none;
                width: 100%;
                justify-content: center;
                &.dropView {
                    display: flex;
                    width: 100%;
                    position: fixed;
                    flex-direction: column;
                    right: 0;
                    top: 0;
                    background: #141C23;
                    z-index: 2;
                    padding: 5vh 1vw;
                    li {
                        margin: 1vh 5vw;
                        &.active {
                            color: #B0E6FC;
                            &::after {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
  }

.homepage {
    
   .topBanner {
    width: 60%;
    text-align: center;
    margin: auto;
    // display: flex;
    // justify-content: center;
    img {
        width: 100%;
        margin: -5vh 0;
    }
   }
   .aboutWrapper {
    background: none;
    padding: 10vh 0;
        .midSection {
            margin-top: 10vh;
            text-align: center;
            .subInfo {
                width: 60%;
                margin: auto;
            }

            .bottom {
                justify-content: center;
                .card {
                    margin-left: 1vh;
                    width: 40%;
                    padding: 2vh 2vw;
                    &.small {
                        padding: 0;
                    }
                    
                }
                &.smallCardContainer {
                    width: 60%;
                    flex-wrap: wrap;
                    margin: auto;
                    display: flex;
                    .card {
                        display: flex;
                        justify-content: center;
                        // justify-content: space-between;
                        span {
                            // line-height: 100%;
                            // width: 30%;
                            // border: 1px solid red;
                            display: block;
                        }
                        h3 {
                            display: block;
                            width: 60%;
                            margin-left: -2vh;
                            // border: 1px solid red;
                            font-size: 2vh;
                        }

                    }
                    
                }
            }
        }

        .bottomSection {
            margin: 10vh auto;
            // border: 1px solid red;
            width: 60%;
            text-align: center;
            h1 {
                text-align: center;
            }
            .listItems {
                padding: 2vh 0;
                text-align: justify;
                strong {
                    &::after {
                        content: ': ';
                    }
                }
            }
            .imgContainer {
                img {
                    max-height: 30vh;
                }
            }
            h4 {
                margin: 1vh 0;
                font-size: 2.1vh;
            }
            .photoInfo {
                margin: 2vh auto;
                font-weight: 400;
            }
            .linkdinIcon {
                cursor: pointer;
            }
        }
        .selectBtn {
            display: none;
        }
   }
}




@media screen and (min-width: 100px) and (max-width: 768px) {
    .homepage {
        overflow-x: hidden !important;
        .topBanner {
         width: 90%;
        }
        .aboutWrapper {
            padding-top: 5vh;
             .midSection {
                 .subInfo {
                     width: 95%;
                 }
     
                 .bottom {
                     flex-wrap: wrap;
                     margin-top: 2vh;
                     .card {
                         width: 95%;
                         display: block; 
                         
                     }
                     &.smallCardContainer {
                         width: 100%;
                         .card {
                             width: 45%;    
                         }
                         
                     }
                 }
             }
     
             .bottomSection {
                margin-bottom: 2vh;
                width: 80%;
                 .listItems {
                     list-style: none;//
                     display: none;
                     strong {
                        display: block;
                        margin-bottom: 2vh;
                        text-align: center;
                        &::after {
                            display: none;
                        }
                     }
                     &.selected {
                        display: block;
                     }
                 }
                .imgContainer {
                    img {
                        width: 60%;
                    }
                }
             }
             .selectBtn {
                display: block;
                margin: 2vh auto;
                width: fit-content;
                span {
                    margin-right: 2vw;
                }
            }
        }
     }
  
  }